<template>
<router-view/>
</template>

<style>
@import './assets/css/common.css';
@font-face{
    font-family: 'huawei';
    src : url('assets/fonts/HarmonyOS_Sans_SC_Medium.ttf');
}
#app {
    font-family: huawei;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 100%;
}
</style>
